//base color light 1

//violet        #6658dd
//water-green   #1abc9c
//light-blue    #4fc6e1
//orange        #f7b84b
//red           #f1556c
//blue          #4a81d4

//base color light 2 

//orange        #EDA232
//green         #38864f
//red           #DA4B42
//blue          #2882BF
//violet        #7562c0

$dark_orange_shell: #eb7c0e !default;
$light_orange_shell: #f39108 !default;


//green
$dark_green_shell: #1e6734 !default;
$light_green_shell: #319838 !default;

//verdeacqua

$verdeacqua: #55ffba !default;

//red
$dark_red_shell: #be1823 !default;
$light_red_shell: #e20d18 !default;

//blue
$dark_blue1_shell: #29265b !default;
$light_blue1_shell: #253887 !default;

$dark_blue2_shell: #455bd8 !default;
$light_blue2_shell: #4366F4 !default;

//violet
$dark_violet_shell: #652682 !default;
$light_violet_shell: #83378d !default;

//right-side calendar event
$today_green: #38864f34 !default;
$selected_day: #ddab5f4b !default;

//text color
$darkmode_text: #c7d1db !default;
$lightmode_text: #727579 !default;

//border colors
$border_dark: #3c424c !default;
$border_light: #e6ecf0 !default;

//dark background
$bg_dark: #292f38 !default;

//light background
$bg_light: #f6f7f8 !default;
//$bg_light: #dfe2e6 !default;

//divs background
$div_light: #fff !default;


//#91969a