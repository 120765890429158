@import "../../../../common-assets/css/variables";
@import "../../../../common-assets/css/rootui-parts/plugin-chart-chartist";
@import "../../../../common-assets/css/rootui-parts/profile";

.rui-profile-img {
    max-width: 60px !important;
}

.custom-chart-light{
    border: 1px solid #e6ecf0;
    border-radius: 0.25rem;
    padding: 25px;
}

.custom-chart-dark{
    border: 1px solid #3c424c;
    border-radius: 0.25rem;
    padding: 25px;
}

.custom-map-light{
    border: 1px solid #e6ecf0;
    border-radius: 0.25rem;
    padding: 10px;
}

.custom-map-dark{
    border: 1px solid #3c424c;
    border-radius: 0.25rem;
    padding: 10px;
}

.custom-datepicker, 
.custom-datepicker > div.react-datepicker-wrapper, 
.custom-datepicker > div > div.react-datepicker__input-container
.custom-datepicker > div > div.react-datepicker__input-container input {
   width: 100%;
}