@import "./style_shell_custom_colors.scss";

::selection {
    background: #38864f38 !important;
}

//background color
//menu and body
body {
    background-color: #fbfbfd !important;
}

.dropdown-menu {
    background-color: #fbfbfd !important;
}

.rui-navbar-top {
    background-color: #fbfbfd !important;
}

.yaybar.rui-yaybar {
    background-color: #fbfbfd !important;
}

.rui-footer {
    background-color: #fbfbfd !important;
}

.yaybar.rui-yaybar .os-scrollbar {
    background-color: #fbfbfd !important;
}

.yaybar.rui-yaybar .os-scrollbar>.os-scrollbar-track {
    background-color: #fbfbfd !important;
}

//div
.card {
    border-radius: 20px !important;
    background-color: #fff !important;
}

.rui-sign-form-cloud {
    border-radius: 20px !important;
    background-color: #fff !important;
}

.rui-profile .rui-profile-img {
    max-width: 100px !important;
    width: 90px !important;
    height: 90px !important;
}

//Border radius
.css-yk16xz-control {
    border-radius: 20px !important;
}

.css-18dl164-control {
    border-radius: 20px !important;
}

//button
.btn {
    border-radius: 20px !important;
}

.css-lg1fe2-control {
    border-radius: 20px !important;
}

.react-calendar,
.shell-timeline-card {
    border-radius: 20px !important;
    // line-height: unset !important;
    border: 1px solid #e6ecf0 !important;
}

.swal2-styled.swal2-cancel {
    border-radius: 20px !important;
    background-color: $light_red_shell !important;
}

.btn {
    text-transform: initial !important;
}


//background color
//table
.MuiPaper-root {
    background-color: #fff !important;
}

.MuiTableCell-head {
    color: #000 !important;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: rgb(53, 48, 48) !important;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
    color: rgb(53, 48, 48) !important;
}

.MuiTableCell-body {
    color: rgb(53, 48, 48) !important;
}

.MuiPaper-rounded {
    border-radius: 20px !important;
}

.modal-content {
    border-radius: 20px !important;
}

.col-lg-8>div:nth-child(2)>div:nth-child(1)>ul:nth-child(1) {
    border-radius: 10px !important;
}


.rui-widget {
    border: 0px !important;
}

.fc-listWeek-view {
    border-radius: 20px !important;
    border-color: transparent !important;
}

.calendar-body .fc-theme-standard td {
    border-color: transparent !important;
}

.fc-theme-standard .fc-list-day-cushion {
    border-radius: 20px !important;
}

// dashboard

html.rui-night-mode mark {
    color: #a9b1bb !important;
    background-color: #292F38 !important;
}

// planning

.fc-button-primary:disabled {
    cursor: not-allowed;
}

.fc-event:hover {
    background-color: white !important;
}

.fc-list-event-time {
    background-color: transparent !important;
}

html.rui-night-mode .fc-list .fc-list-event-time:hover {
    color: #a9b1bb !important;
}

.fc-list-event-graphic {
    background-color: transparent !important;
}

.fc .fc-list-event:hover td+td+td {
    border-radius: 20px !important;
}

a.edit-attivita {
    float: right;
    color: #4b515b;
}

.rui-profile-info-mail {
    font-size: 0.95em;
}

html.rui-night-mode .fc .fc-list-event:hover td+td+td {
    background-color: #161A1D !important;
    color: #a9b1bb !important;
}

html.rui-night-mode a.edit-attivita {
    color: #a9b1bb !important;
}


html.rui-night-mode .fc-theme-standard .fc-list-day-cushion {
    background-color: #292F38 !important;
    border: 1px solid #494f5a;
}

html.rui-night-mode .fc-theme-standard .fc-list-day-text {
    color: #a9b1bb !important;
}

html.rui-night-mode a.fc-list-day-side-text {
    color: #a9b1bb !important;
}

html.rui-night-mode .fc-list-empty {
    background-color: #292F38 !important;
    border: 1px solid #494f5a;
}

//planning vuoto

/* .fc-list-empty {
    background-color: $border_light !important;
}

.fc-list-empty-cushion {
    color: black !important;
    font-weight: bold;
} */


// ----------------------..................................................-------------------------------------


.swal2-popup .swal2-styled.swal2-confirm {
    background-color: $light_green_shell !important;
    border-radius: 100px !important;
}

//bottone disabilitato

.btn:disabled {
    background-color: gray !important;
    border-color: gray !important;
    opacity: 0.4 !important;
    color: white;
}

//bottone Aggiungi Unità

//bottone verde
.btn-green {
    background-color: $light_green_shell !important;
    border-color: $light_green_shell !important;
}

.btn-green:hover {
    background-color: $dark_green_shell !important;
    border-color: $dark_green_shell !important;
}

//bottone orange

.btn-orange {
    background-color: $light_orange_shell !important;
    border-color: $light_orange_shell !important;
}

.btn-orange:hover {
    background-color: $dark_orange_shell !important;
    border-color: $dark_orange_shell !important;
}

.btn-act-right {
    display: inline;
    margin-left: 0.5em;
}

.btn-act-left {
    display: inline;
    margin-right: 0.5em;
}

.color-red {
    color: red !important;
}

//bottone rosso
.btn-red {
    background-color: $light_red_shell !important;
    border-color: $light_red_shell !important;
}

.btn-red:hover {
    background-color: $dark_red_shell !important;
    border-color: $dark_red_shell !important;
}

.btn-blue {
    background-color: $light_blue1_shell !important;
    border-color: $light_blue1_shell !important;
}

.btn-blue:hover {
    background-color: $dark_blue1_shell !important;
    border-color: $dark_blue1_shell !important;
}

//right-side-calendar
.react-calendar {
    width: 100% !important;
    min-width: auto !important;
    line-height: 1.80em !important;
    border-color: $border_light !important;
    background-color: $div_light !important;
    border-radius: 0.25rem;
}

.react-calendar__tile {
    line-height: 24px !important;
}

.react-calendar__tile--active {
    background: $light_orange_shell !important;
    color: white !important;
    font-weight: bold !important;
    border-radius: 20px !important;
}

.react-calendar__tile--now {
    background: $light_green_shell !important;
    color: $light_blue2_shell !important;
    font-weight: bold !important;
    border-radius: 20px !important;
}

.react-calendar__tile:hover {
    background: $light_orange_shell !important;
    color: white !important;
    font-weight: bold !important;
    border-radius: 20px !important;
}

.react-calendar__month-view__days__day--weekend {
    color: $dark_orange_shell;
    font-weight: bold !important;
}

.react-calendar__tile--now {
    color: white !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: gray !important;
}

.react-calendar__navigation__label {
    border-radius: 0.25rem;

}

.fc-aggiungiBtn-button {
    background-color: $light_orange_shell !important;
    border-color: $light_orange_shell !important;
    font-weight: bold !important;
    border-radius: 5px !important;
}

.fc-aggiungiBtn-button:hover {
    background-color: $dark_orange_shell !important;
    border-color: $dark_orange_shell !important;

}

.fc-validaBtn-button {
    background-color: $light_green_shell !important;
    border-color: $light_green_shell !important;
    font-weight: bold !important;
    border-radius: 5px !important;
}

.fc-validaBtn-button:hover {
    background-color: $dark_green_shell !important;
    border-color: $dark_green_shell !important;
}

.fc-today-button {
    background-color: $light_red_shell !important;
    border-color: $light_red_shell !important;
    font-weight: bold !important;
    border-radius: 5px !important;
}

.fc-today-button:hover {
    background-color: $dark_red_shell !important;
    border-color: $dark_red_shell !important;
}

.fc-questionBtn-button {
    background-color: $light_blue2_shell !important;
    border-color: $light_blue2_shell !important;
    font-weight: bold !important;
    border-radius: 5px !important;
}

.fc-questionBtn-button:hover {
    background-color: $dark_blue2_shell !important;
    border-color: $dark_blue2_shell !important;
}

//nota spese
.MuiPaper-elevation1 {
    border: solid 1px $border_light !important;
    box-shadow: 0 0 0 0 !important
}

.TableCell-cellNoWrap-39 {
    border-right: solid 1px $border_light !important;
}

//calendar auto aziendali


.fc-day-today {
    background-color: $today_green !important;
    color: $dark_orange_shell !important;
}

.fc-event-main {
    color: $lightmode_text !important;
    font-weight: bold !important;
}

.fc-event {
    border: 1px solid $light_red_shell;
    border-left-color: $light_red_shell !important;
    background-color: #fff !important;
    border-left-width: 4px !important;
    border-radius: .2rem;
    padding: 4px 10px 6px !important;
}

.fc-scrollgrid {
    border: 1px solid $border_light !important;
    border-radius: 0.25rem !important;
    --fc-border-color: #e6ecf0 !important;
    border-bottom-width: 0 !important;
    border-right-width: 0 !important;
    border-collapse: separate !important;
}

//user notaspese
.select__control {
    border-color: $border_light !important;
    border-radius: 20px !important;
}

//smartworking button
.smart-btn {
    background-color: $light_blue1_shell !important;
    border-color: $light_blue1_shell !important;
    margin: 4px !important;
    float: right !important;
    color: white;
}

.smart-btn:hover {
    background-color: $dark_blue1_shell !important;
    border-color: $dark_blue1_shell !important;
    color: white;
}

//side-nav active Icon color
.yaybar.rui-yaybar .yaybar-wrap ul>li.yay-item-active>a>.yay-icon {
    color: $dark_orange_shell !important;
}

.yaybar.rui-yaybar .yaybar-wrap ul>li.yay-item-active>a>span:not([class]) {
    color: $lightmode_text !important;
}

.nav .nav-link.active {
    color: $dark_orange_shell !important;
}

.nav .nav-link.active>span:not([class]) {
    color: $lightmode_text !important;
}

.nav .nav-link>span:not([class]) {
    color: $lightmode_text !important;
}

// colore attività planning
.rui-calendar .fc-list .fc-list-event.fc-event-brand .fc-list-event-dot {
    border-color: $light_green_shell !important;
}

//planning vuoto

.fc .fc-list-empty {
    border-radius: 20px !important;
}

//!!!cambiare colore in dark-mode
.rui-calendar .fc-list .fc-list-empty {
    background-color: transparent !important;
}

.fc .fc-list-sticky .fc-list-day>* {
    background-color: #fff !important;
}

//user section border
.yaybar.rui-yaybar .yaybar-wrap ul>li>a>span:not([class]) {
    color: #727579 !important;
}

.reday {
    color: $dark_orange_shell !important;
}

//checkbox

.custom-control-input:checked~.custom-control-label::before {
    background-color: $light_green_shell !important;
    border-color: $light_green_shell !important;
}

//clienti

.MuiTypography-h6 {
    font-family: "Nunito Sans", sans-serif !important;
    font-size: 1.231rem !important;
    font-weight: 400 !important;
}

.MuiTableCell-root {
    font-family: "Nunito Sans", sans-serif !important;
    font-size: 1rem !important;
}

.MuiTypography-body2 {
    font-family: "Nunito Sans", sans-serif !important;
    font-size: 1rem !important;
}


// .ferie-icon {
//     left: 0;
//     float: none;
//     display: inline-block;
//     padding-left: 20px;
//     color: $lightmode_text;
// }

.nav-tabs {
    border-bottom: 0px solid #dee2e6 !important;
}


.react-calendar__navigation button {
    border-radius: 20px;
}

.rui-calendar .fc-toolbar .fc-button {
    border-radius: 20px;
}

//scrollbar
.yaybar.rui-yaybar .os-scrollbar {
    background-color: transparent !important;
}

.yaybar.rui-yaybar .os-scrollbar>.os-scrollbar-track {
    background-color: transparent !important;
}

.yaybar.rui-yaybar .os-scrollbar>.os-scrollbar-track>.os-scrollbar-handle {
    background-color: transparent !important;
}


//select color

.css-1pahdxg-control {
    border-color: #da942d99 !important;
    border-width: 1px !important;
    border-style: solid !important;
}

.select__value-container .css-g1d714-ValueContainer {
    border-radius: 20px !important;
}

//head calendar

.rui-calendar .fc-list .fc-list-day-cushion {
    border-radius: 20px;
    background-color: #161a1d21;
}


//datepicker

.form-control {
    border-radius: 20px !important;
    background-color: $div_light !important;
    border-color: $border_light !important;
}

.react-datepicker {
    border-radius: 20px !important;
}

.css-26l3qy-menu {
    overflow: visible !important;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
    background-color: white !important;
    border: 1px solid $border_light !important;
}



//dashboard

.media.media-filled:hover>.media-link,
.media.media-filled.hover>.media-link,
.media.media-filled.active>.media-link {
    border-radius: 20px !important;
}

.list-group-item {
    border-radius: 20px !important;
    background-color: #fff !important;
    backdrop-filter: blur(0.5px) !important;
    -webkit-backdrop-filter: blur(0.5px) !important;
    margin-top: 4px
}

.rui-task:hover {
    border-radius: 20px !important;
}

.custom-options {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .dsg-container{
    overflow: unset !important;
    --dsg-scroll-shadow-width: 0px !important;
  } */

.fc .fc-list-table tbody>tr th {
    border-bottom: 0;
}

.media.media-filled>.media-link {
    border-radius: 20px !important
}

.rui-task {
    border-radius: 20px !important
}

.cog {
    -webkit-animation: cog 1s infinite;
    -moz-animation: cog 1s infinite;
    -ms-animation: cog 1s infinite;
    animation: cog 1s infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
    animation-timing-function: linear
}

@keyframes cog {
    100% {
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}